import srcset from 'srcset';

import { MediaServerURL } from './media-server-url.js';
import type { GravityRegion } from './types.js';

type RatioString = `${number}:${number}`;
type Ratio = [number, number] | RatioString;

export interface ResponsiveImgOptions {
  width: number;
  densities?: number[];
  quality?: number;
  ratio?: Ratio;
  gravity?: GravityRegion;
}

export function getResponsiveImgAttributes(
  imageUrl: MediaServerURL | URL | string | undefined | null,
  {
    width,
    densities = [1, 1.5, 2],
    quality = 75,
    ratio,
    gravity,
  }: ResponsiveImgOptions,
) {
  if (!imageUrl || (typeof imageUrl === 'string' && !URL.canParse(imageUrl))) {
    return { src: imageUrl ?? undefined, srcSet: undefined };
  }

  const mediaServerURL =
    imageUrl instanceof MediaServerURL ? imageUrl : (
      MediaServerURL.fromURL(imageUrl)
    );

  if (gravity) {
    mediaServerURL.gravity(gravity);
  }

  if (ratio) {
    const parsedRatio =
      typeof ratio === 'string' ?
        (ratio.split(':').map(value => Number.parseInt(value.trim())) as [
          number,
          number,
        ])
      : ratio;

    mediaServerURL.ratio(...parsedRatio);
  }

  return {
    src: mediaServerURL.clone().scale(width).quality(quality).toString(),
    srcSet:
      densities ?
        srcset.stringify(
          densities?.map(d => ({
            density: d,
            url: mediaServerURL
              .clone()
              .scale(Math.ceil(width * d))
              .quality(quality)
              .toString(),
          })),
        )
      : undefined,
  };
}
